import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { LocaleProvider } from '../../../hooks/useLocale';
import type { ThankYouWidgetProps } from '../ThankYouWidgetController';
import ThankYouWidget from './ThankYouWidget';

export default function (props: WidgetProps<ThankYouWidgetProps>) {
  return (
    <LocaleProvider locale={props.locale}>
      <ThankYouWidget {...props} />
    </LocaleProvider>
  );
}
