import React, { HTMLProps, useEffect, useRef } from 'react';

interface FocusedHeadingProps extends HTMLProps<HTMLHeadingElement> {
  'data-hook'?: string;
  as?: string;
}

export const FocusedHeading: React.FC<FocusedHeadingProps> = ({ as, children, ...attributes }) => {
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return React.createElement(as || 'h1', { ...attributes, ref, tabIndex: -1 }, children);
};
