import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import Status from '../../PackagePicker/Widget/Status';
import type { ThankYouWidgetProps } from '../ThankYouWidgetController';

const ThankYouWidget: React.FC<WidgetProps<ThankYouWidgetProps>> = (props) => {
  return <Status {...props} />;
};

export default ThankYouWidget;
